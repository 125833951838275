/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
Essentals
*/

// import specific babel polyfills for ie11
import 'core-js/es/array';
import 'core-js/web/dom-collections';

import $ from 'jquery'; // eslint-disable-line

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
NPM Packages
*/


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
Custom Scripts
*/

import simpleParallax from './libs/simple-parallax';
import  './libs/bootstrap.bundle.min';


$(() => {
    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    Initialize parallax images
    */
    var images = document.querySelectorAll('.parallax');
    new simpleParallax(images);

    /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    Initialize CMS-Plugins after Ajax-Request
    */
    if (typeof CMS !== 'undefined') {
        $(window).on('ajaxLoadItems:success', () => {
            CMS.Plugin._initializeTree(); // eslint-disable-line
        });
    }

    $('.nav-toggle').click((e) => {
        $('.nav-toggle').toggleClass('is-active');
        $('.nav__large').toggleClass('nav__large--show-mobile');
        $('body').toggleClass('scroll-lock');
    });

    window.setMobileTable = function(selector) {
      const tableEl = document.querySelectorAll(selector);
      tableEl.forEach(tableEl => {
          const thEls = tableEl.querySelectorAll('thead th');
          const tdLabels = Array.from(thEls).map(el => el.innerText);
          tableEl.querySelectorAll('tbody tr').forEach( tr => {
            Array.from(tr.children).forEach(
              (td, ndx) =>  {
                  if (tdLabels[ndx] != undefined) {
                      td.setAttribute('label', tdLabels[ndx])
                  }
              }
            );
          });
      })
    }

    window.setMobileTable("table");
});
